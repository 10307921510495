import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Loader, Plus } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PromptsTable } from "@/components/prompts/PromptsTable";
import { Button } from "@/components/ui/button";
import { Tables } from "@/integrations/supabase/types/tables";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

type SmartPrompt = Tables<'smart_prompts'>;
type UserPrompt = Tables<'user_prompts'>;

const Prompts = () => {
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState<Partial<SmartPrompt | UserPrompt>>({});
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [newPrompt, setNewPrompt] = useState({ action: "", prompt: "" });
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: masterPrompts, isLoading: isLoadingMaster } = useQuery({
    queryKey: ["smart-prompts"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("smart_prompts")
        .select("*")
        .order("action");

      if (error) {
        console.error("Error fetching master actions:", error);
        toast({
          variant: "destructive",
          title: "Error fetching actions",
          description: error.message,
        });
        throw error;
      }

      return data as SmartPrompt[];
    },
  });

  const { data: userPrompts, isLoading: isLoadingUser } = useQuery({
    queryKey: ["user-prompts"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("user_prompts")
        .select("*")
        .order("action");

      if (error) {
        console.error("Error fetching user actions:", error);
        toast({
          variant: "destructive",
          title: "Error fetching user actions",
          description: error.message,
        });
        throw error;
      }

      return data as UserPrompt[];
    },
  });

  const handleEdit = (prompt: SmartPrompt | UserPrompt) => {
    setEditingId(prompt.id);
    setEditForm(prompt);
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditForm({});
  };

  const handleSave = async () => {
    await queryClient.invalidateQueries({ queryKey: ["smart-prompts"] });
    await queryClient.invalidateQueries({ queryKey: ["user-prompts"] });
    setEditingId(null);
    setEditForm({});
  };

  const handleCreatePrompt = async () => {
    if (!newPrompt.action || !newPrompt.prompt) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: "Both action name and template are required",
      });
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");

      // Get the current highest position for this user
      const { data: existingPrompts } = await supabase
        .from('user_prompts')
        .select('position')
        .eq('user_id', user.id)
        .order('position', { ascending: false })
        .limit(1);

      const nextPosition = existingPrompts && existingPrompts.length > 0 
        ? (existingPrompts[0].position + 1) 
        : 1;

      const { error } = await supabase.from("user_prompts").insert({
        action: newPrompt.action,
        prompt: newPrompt.prompt,
        user_id: user.id,
        position: nextPosition
      });

      if (error) throw error;

      toast({
        title: "Success",
        description: "Action created successfully",
      });
      setIsCreateDialogOpen(false);
      setNewPrompt({ action: "", prompt: "" });
      queryClient.invalidateQueries({ queryKey: ["user-prompts"] });
    } catch (error) {
      console.error("Error creating action:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to create action",
      });
    }
  };

  const handleDeleteUserPrompt = async (id: string) => {
    try {
      const { error } = await supabase
        .from("user_prompts")
        .delete()
        .eq("id", id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Action deleted successfully",
      });
      queryClient.invalidateQueries({ queryKey: ["user-prompts"] });
    } catch (error) {
      console.error("Error deleting action:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete action",
      });
    }
  };

  if (isLoadingMaster || isLoadingUser) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8">
      <div className="space-y-8">
        <div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Master Actions</h2>
          </div>
          {masterPrompts && masterPrompts.length > 0 ? (
            <PromptsTable
              prompts={masterPrompts}
              editingId={editingId}
              editForm={editForm}
              setEditForm={setEditForm}
              handleEdit={handleEdit}
              handleSave={handleSave}
              handleCancelEdit={handleCancelEdit}
            />
          ) : (
            <div className="text-center text-gray-500">No master actions found.</div>
          )}
        </div>

        <div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">My Actions</h2>
            <Button onClick={() => setIsCreateDialogOpen(true)}>
              <Plus className="h-4 w-4 mr-2" />
              Create Action
            </Button>
          </div>
          {userPrompts && userPrompts.length > 0 ? (
            <PromptsTable
              prompts={userPrompts}
              editingId={editingId}
              editForm={editForm}
              setEditForm={setEditForm}
              handleEdit={handleEdit}
              handleSave={handleSave}
              handleCancelEdit={handleCancelEdit}
              isUserPrompt
              onDelete={handleDeleteUserPrompt}
            />
          ) : (
            <div className="text-center text-gray-500">
              No custom actions found. Create your first action!
            </div>
          )}
        </div>
      </div>

      <Dialog open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create New Action</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <label htmlFor="new-action">Action Name</label>
              <Input
                id="new-action"
                value={newPrompt.action}
                onChange={(e) =>
                  setNewPrompt({ ...newPrompt, action: e.target.value })
                }
                placeholder="e.g., Make Professional"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="new-prompt">Action Template</label>
              <Textarea
                id="new-prompt"
                value={newPrompt.prompt}
                onChange={(e) =>
                  setNewPrompt({ ...newPrompt, prompt: e.target.value })
                }
                placeholder="Enter your action template..."
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsCreateDialogOpen(false);
                setNewPrompt({ action: "", prompt: "" });
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleCreatePrompt}>Create</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Prompts;