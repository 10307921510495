import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useToast } from "@/hooks/use-toast";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";

interface AddActionDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  userId: string | null;
}

export const AddActionDialog = ({ isOpen, onOpenChange, userId }: AddActionDialogProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: smartPrompts } = useQuery({
    queryKey: ["smart-prompts"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("smart_prompts")
        .select("*")
        .eq("is_active", true)
        .order("action");

      if (error) throw error;
      return data;
    },
  });

  const handleAddAction = async (action: string, prompt: string) => {
    if (!userId) return;

    try {
      // Get the current highest position for this user
      const { data: existingPrompts } = await supabase
        .from("user_prompts")
        .select("position")
        .eq("user_id", userId)
        .order("position", { ascending: false })
        .limit(1);

      const nextPosition = existingPrompts && existingPrompts.length > 0
        ? (existingPrompts[0].position + 1)
        : 1;

      const { error } = await supabase.from("user_prompts").insert({
        user_id: userId,
        action,
        prompt,
        position: nextPosition,
      });

      if (error) throw error;

      toast({
        title: "Success",
        description: "Action added successfully",
      });

      queryClient.invalidateQueries({ queryKey: ["user-prompts", userId] });
    } catch (error) {
      console.error("Error adding action:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to add action",
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>Add Action from Library</DialogTitle>
        </DialogHeader>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Action</TableHead>
                <TableHead>Template</TableHead>
                <TableHead className="w-[100px]">Add</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {smartPrompts?.map((prompt) => (
                <TableRow key={prompt.id}>
                  <TableCell>{prompt.action}</TableCell>
                  <TableCell>{prompt.prompt}</TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleAddAction(prompt.action, prompt.prompt)}
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
    </Dialog>
  );
};