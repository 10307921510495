import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Settings } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Tables } from "@/integrations/supabase/types/tables";

type UserSubscription = {
  subscription_type: string | null;
  is_active: boolean | null;
};

type User = Tables<"profiles"> & {
  subscriptions: UserSubscription[];
  email: string | null;
  isAdmin: boolean;
};

interface UsersListProps {
  users: User[];
  onManagePrompts: (userId: string) => void;
  onToggleAdminStatus: (userId: string, currentStatus: boolean) => void;
}

export const UsersList = ({ users, onManagePrompts, onToggleAdminStatus }: UsersListProps) => {
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Company</TableHead>
            <TableHead>Role</TableHead>
            <TableHead>Subscription</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                {user.first_name || user.last_name
                  ? `${user.first_name || ""} ${user.last_name || ""}`
                  : "N/A"}
              </TableCell>
              <TableCell>{user.email || "N/A"}</TableCell>
              <TableCell>{user.company || "N/A"}</TableCell>
              <TableCell>
                <Badge variant={user.role === 'admin' ? "destructive" : "secondary"}>
                  {user.role || "user"}
                </Badge>
              </TableCell>
              <TableCell>
                <Badge variant={user.subscriptions?.[0]?.is_active ? "default" : "secondary"}>
                  {user.subscriptions?.[0]?.subscription_type || "free"}
                </Badge>
              </TableCell>
              <TableCell className="space-x-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => onToggleAdminStatus(user.id, user.role === 'admin')}
                  title={user.role === 'admin' ? "Remove admin role" : "Make admin"}
                >
                  <Badge variant={user.role === 'admin' ? "destructive" : "outline"}>
                    A
                  </Badge>
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => onManagePrompts(user.id)}
                >
                  <Settings className="h-4 w-4" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};