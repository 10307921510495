import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Users, DollarSign, Link } from "lucide-react";

type AffiliateStats = {
  referralCount: number;
  totalEarnings: number;
  referralCode: string;
};

export const AffiliateStats = () => {
  const [stats, setStats] = useState<AffiliateStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    fetchAffiliateStats();
  }, []);

  const fetchAffiliateStats = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Get affiliate data including referral code and total earnings
      const { data: affiliateData, error: affiliateError } = await supabase
        .from('affiliates')
        .select('id, referral_code, total_earnings')
        .eq('user_id', user.id)
        .single();

      if (affiliateError) throw affiliateError;

      if (!affiliateData) {
        setIsLoading(false);
        return;
      }

      // Get count of referrals
      const { count: referralCount, error: referralError } = await supabase
        .from('referrals')
        .select('*', { count: 'exact' })
        .eq('affiliate_id', affiliateData.id);

      if (referralError) throw referralError;

      setStats({
        referralCount: referralCount || 0,
        totalEarnings: affiliateData.total_earnings || 0,
        referralCode: affiliateData.referral_code,
      });
    } catch (error) {
      console.error('Error fetching affiliate stats:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch affiliate statistics.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const copyReferralLink = () => {
    if (!stats?.referralCode) return;
    const link = `${window.location.origin}?ref=${stats.referralCode}`;
    navigator.clipboard.writeText(link);
    toast({
      title: "Success",
      description: "Referral link copied to clipboard!",
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!stats) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Total Referrals</CardTitle>
          <Users className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{stats.referralCount}</div>
          <p className="text-xs text-muted-foreground">Registered users</p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Total Earnings</CardTitle>
          <DollarSign className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">${stats.totalEarnings.toFixed(2)}</div>
          <p className="text-xs text-muted-foreground">Lifetime earnings</p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Referral Code</CardTitle>
          <Link className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold font-mono">{stats.referralCode}</div>
          <Button 
            variant="outline" 
            size="sm" 
            className="mt-2 w-full"
            onClick={copyReferralLink}
          >
            Copy Link
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};