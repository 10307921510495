import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

export const HeroSection = () => {
  return (
    <div className="relative h-[500px] mb-16">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900/90 to-purple-900/90">
        <img
          src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d"
          alt="Hero background"
          className="w-full h-full object-cover mix-blend-overlay"
        />
      </div>
      <div className="relative container mx-auto px-4 h-full flex flex-col justify-center items-center text-white z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center max-w-3xl"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="mb-4"
          >
            <span className="px-4 py-1.5 rounded-full bg-white/10 text-sm font-medium backdrop-blur-sm border border-white/20">
              10x Your Writing Productivity
            </span>
          </motion.div>
          <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-blue-200 to-purple-200 bg-clip-text text-transparent">
            Boost Your Writing Efficiency with QuickPick
          </h1>
          <p className="text-xl mb-8 text-gray-200 leading-relaxed">
            Transform your writing process and save hours of work. Whether it's emails, documents, or social media posts - enhance your content instantly with AI-powered assistance.
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <Link to="/login">
              <Button
                size="lg"
                className="w-full sm:w-auto bg-gradient-to-r from-[#8B5CF6] to-[#6366F1] text-white hover:opacity-90 px-8 shadow-lg hover:shadow-xl transition-all duration-300"
              >
                Start Writing Faster
              </Button>
            </Link>
            <Link to="/affiliate">
              <Button
                size="lg"
                variant="outline"
                className="w-full sm:w-auto border-black border-2 text-black hover:bg-black hover:text-white px-8 backdrop-blur-sm font-semibold shadow-lg hover:shadow-xl transition-all duration-300"
              >
                Become an Affiliate
              </Button>
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
};