import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Loader } from "lucide-react";
import { UsersList } from "@/components/admin/UsersList";
import { UserPromptsDialog } from "@/components/admin/UserPromptsDialog";
import { useToast } from "@/hooks/use-toast";

const AdminUsers = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const { toast } = useToast();

  const { data: users, isLoading, error, refetch } = useQuery({
    queryKey: ["admin-users"],
    queryFn: async () => {
      try {
        // First check if user is admin
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) throw new Error('Not authenticated');
        
        const { data: currentUser, error: profileError } = await supabase
          .from("profiles")
          .select("role")
          .eq("id", session.user.id)
          .single();
        
        if (profileError) throw profileError;
        if (currentUser.role !== 'admin') {
          throw new Error('Unauthorized: Admin access required');
        }

        const { data: usersData, error: usersError } = await supabase
          .from("profiles")
          .select(`
            *,
            subscriptions (
              subscription_type,
              is_active
            )
          `);

        if (usersError) throw usersError;

        // Fetch emails using the Edge Function
        const response = await fetch(
          'https://bfxkfzqmahlmpkyznqsz.supabase.co/functions/v1/list-users',
          {
            headers: {
              Authorization: `Bearer ${session.access_token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch user emails');
        }

        const { users: authUsers } = await response.json();

        // Transform the data to match the User type
        const transformedUsers = usersData.map(user => ({
          ...user,
          email: authUsers.find((authUser: { id: string; email: string }) => authUser.id === user.id)?.email || null,
          isAdmin: user.role === 'admin'
        }));

        return transformedUsers;
      } catch (error) {
        console.error('Error in admin users query:', error);
        throw error;
      }
    },
    meta: {
      errorMessage: "Failed to load users"
    }
  });

  const toggleAdminStatus = async (userId: string, isCurrentlyAdmin: boolean) => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({ role: isCurrentlyAdmin ? 'user' : 'admin' })
        .eq('id', userId);

      if (error) throw error;

      toast({
        title: "Success",
        description: `User role ${isCurrentlyAdmin ? 'revoked' : 'granted'} successfully`,
      });

      // Refresh the users list
      refetch();
    } catch (error) {
      console.error('Error toggling admin status:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update user role"
      });
    }
  };

  // Show error toast when error changes
  useEffect(() => {
    if (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to load users"
      });
    }
  }, [error, toast]);

  if (error) {
    return (
      <div className="container mx-auto py-8">
        <h1 className="text-3xl font-bold mb-8">User Management</h1>
        <div className="text-red-500">
          {error instanceof Error ? error.message : "An error occurred while loading users"}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-8">User Management</h1>
      <UsersList 
        users={users || []} 
        onManagePrompts={setSelectedUserId}
        onToggleAdminStatus={toggleAdminStatus}
      />
      <UserPromptsDialog
        userId={selectedUserId}
        open={!!selectedUserId}
        onOpenChange={(open) => !open && setSelectedUserId(null)}
      />
    </div>
  );
};

export default AdminUsers;