import { motion } from "framer-motion";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Wand2, Chrome, Sparkles, MessageSquare } from "lucide-react";

const features = [
  {
    icon: <Chrome className="h-8 w-8 text-blue-500" />,
    title: "Chrome Extension",
    description: "Enhance your text directly in any text field across the web with our powerful Chrome extension."
  },
  {
    icon: <Wand2 className="h-8 w-8 text-purple-500" />,
    title: "Smart Text Enhancement",
    description: "Transform your text with various enhancement options including formal, casual, and translation capabilities."
  },
  {
    icon: <Sparkles className="h-8 w-8 text-pink-500" />,
    title: "Multiple Enhancement Modes",
    description: "Choose from different enhancement modes to perfectly match your needs - improve writing, make formal, or translate."
  },
  {
    icon: <MessageSquare className="h-8 w-8 text-indigo-500" />,
    title: "Real-time Processing",
    description: "Experience instant text transformations with our real-time processing capabilities."
  }
];

export const FeaturesSection = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center mb-12"
      >
        <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          Powerful Features for Enhanced Writing
        </h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Unlock the full potential of your writing with our comprehensive suite of tools
        </p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ 
              scale: 1.02,
              transition: { duration: 0.2 }
            }}
          >
            <Card className="h-full bg-gradient-to-br from-white to-gray-50/50 border-black/5 shadow-lg hover:shadow-xl transition-all duration-300 hover:border-blue-500/20">
              <CardHeader className="flex flex-row items-center gap-4 pb-2">
                <div className="p-2 rounded-xl bg-white shadow-sm">
                  {feature.icon}
                </div>
                <CardTitle className="text-xl bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
                  {feature.title}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600 leading-relaxed">
                  {feature.description}
                </p>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
};