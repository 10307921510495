import { useEffect, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Pencil, Save, X } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useQuery, useQueryClient } from "@tanstack/react-query";

interface SmartPrompt {
  id: string;
  action: string;
  prompt: string;
  created_at: string;
  updated_at: string;
}

const Admin = () => {
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState<Partial<SmartPrompt>>({});
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: prompts, isLoading } = useQuery({
    queryKey: ["smart-prompts"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("smart_prompts")
        .select("*")
        .order("action");

      if (error) {
        toast({
          variant: "destructive",
          title: "Error fetching prompts",
          description: error.message,
        });
        throw error;
      }

      return data as SmartPrompt[];
    },
  });

  const handleEdit = (prompt: SmartPrompt) => {
    setEditingId(prompt.id);
    setEditForm(prompt);
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditForm({});
  };

  const handleSave = async () => {
    if (!editForm.action || !editForm.prompt) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: "Both action and prompt are required",
      });
      return;
    }

    const { error } = await supabase
      .from("smart_prompts")
      .update({
        action: editForm.action,
        prompt: editForm.prompt,
      })
      .eq("id", editingId);

    if (error) {
      toast({
        variant: "destructive",
        title: "Error updating prompt",
        description: error.message,
      });
      return;
    }

    toast({
      title: "Success",
      description: "Prompt updated successfully",
    });

    setEditingId(null);
    setEditForm({});
    queryClient.invalidateQueries({ queryKey: ["smart-prompts"] });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-8">Smart Prompts Admin</h1>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Action</TableHead>
              <TableHead>Prompt</TableHead>
              <TableHead className="w-[100px]">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {prompts?.map((prompt) => (
              <TableRow key={prompt.id}>
                <TableCell>
                  {editingId === prompt.id ? (
                    <Input
                      value={editForm.action}
                      onChange={(e) =>
                        setEditForm({ ...editForm, action: e.target.value })
                      }
                    />
                  ) : (
                    prompt.action
                  )}
                </TableCell>
                <TableCell>
                  {editingId === prompt.id ? (
                    <Textarea
                      value={editForm.prompt}
                      onChange={(e) =>
                        setEditForm({ ...editForm, prompt: e.target.value })
                      }
                    />
                  ) : (
                    prompt.prompt
                  )}
                </TableCell>
                <TableCell>
                  {editingId === prompt.id ? (
                    <div className="flex gap-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={handleSave}
                        className="h-8 w-8"
                      >
                        <Save className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={handleCancelEdit}
                        className="h-8 w-8"
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleEdit(prompt)}
                      className="h-8 w-8"
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Admin;