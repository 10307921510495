import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { PromptRow } from "./PromptRow";
import { Tables } from "@/integrations/supabase/types/tables";

type SmartPrompt = Tables<'smart_prompts'>;
type UserPrompt = Tables<'user_prompts'>;
type Prompt = SmartPrompt | UserPrompt;

interface PromptsTableProps {
  prompts: Prompt[];
  editingId: string | null;
  editForm: Partial<Prompt>;
  setEditForm: (form: Partial<Prompt>) => void;
  handleEdit: (prompt: Prompt) => void;
  handleSave: () => Promise<void>;
  handleCancelEdit: () => void;
  isUserPrompt?: boolean;
  onDelete?: (id: string) => Promise<void>;
}

export const PromptsTable = ({
  prompts,
  editingId,
  editForm,
  setEditForm,
  handleEdit,
  handleSave,
  handleCancelEdit,
  isUserPrompt = false,
  onDelete,
}: PromptsTableProps) => {
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Action Name</TableHead>
            <TableHead>Template</TableHead>
            <TableHead className="w-[150px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {prompts.map((prompt) => (
            <PromptRow
              key={prompt.id}
              prompt={prompt}
              onEdit={handleEdit}
              onSave={handleSave}
              onCancel={handleCancelEdit}
              editForm={editForm}
              setEditForm={setEditForm}
              isEditing={editingId === prompt.id}
              isUserPrompt={isUserPrompt}
              onDelete={onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};