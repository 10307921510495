import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Loader2, CheckCircle2, XCircle } from "lucide-react";
import { AffiliateStats } from "@/components/affiliate/AffiliateStats";

type AffiliateData = {
  id: string;
  status: string;
  referral_code: string;
  commission_rate: number;
  total_earnings: number;
  description: string;
  website_url: string | null;
  twitter_url: string | null;
  linkedin_url: string | null;
  instagram_url: string | null;
  activity_description: string | null;
};

const AffiliatePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [affiliateData, setAffiliateData] = useState<AffiliateData | null>(null);
  const [formData, setFormData] = useState({
    description: "",
    activity_description: "",
    website_url: "",
    twitter_url: "",
    linkedin_url: "",
    instagram_url: "",
  });
  const { toast } = useToast();

  useEffect(() => {
    fetchAffiliateData();
  }, []);

  const fetchAffiliateData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('affiliates')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      
      if (data) {
        setAffiliateData(data);
        setFormData({
          description: data.description || "",
          activity_description: data.activity_description || "",
          website_url: data.website_url || "",
          twitter_url: data.twitter_url || "",
          linkedin_url: data.linkedin_url || "",
          instagram_url: data.instagram_url || "",
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch affiliate data.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      if (!affiliateData) {
        // Generate new referral code and create affiliate record
        const { data: referralCode, error: functionError } = await supabase
          .rpc('generate_unique_referral_code');

        if (functionError) throw functionError;

        // The referral code is automatically inserted by the function
        // We just need to update the other fields
        const { error: updateError } = await supabase
          .from('affiliates')
          .update(formData)
          .eq('user_id', user.id);

        if (updateError) throw updateError;

        toast({
          title: "Application Submitted!",
          description: "We'll review your application soon.",
        });
      } else {
        // Update existing affiliate record
        const { error: updateError } = await supabase
          .from('affiliates')
          .update(formData)
          .eq('id', affiliateData.id);

        if (updateError) throw updateError;

        toast({
          title: "Success",
          description: "Your affiliate information has been updated.",
        });
      }

      fetchAffiliateData();
    } catch (error) {
      console.error('Error:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to submit application. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <AffiliateStats />
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            {!affiliateData ? "Become an Affiliate" : "Affiliate Dashboard"}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {affiliateData && (
            <div className="mb-8 space-y-4">
              <div className="flex items-center space-x-2">
                <span className="font-semibold">Status:</span>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  affiliateData.status === 'approved'
                    ? 'bg-green-100 text-green-800'
                    : affiliateData.status === 'rejected'
                    ? 'bg-red-100 text-red-800'
                    : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {affiliateData.status === 'approved' && <CheckCircle2 className="w-4 h-4 mr-1" />}
                  {affiliateData.status === 'rejected' && <XCircle className="w-4 h-4 mr-1" />}
                  {affiliateData.status.charAt(0).toUpperCase() + affiliateData.status.slice(1)}
                </span>
              </div>
              {affiliateData.status === 'approved' && (
                <>
                  <div>
                    <span className="font-semibold">Your Referral Code:</span>
                    <code className="ml-2 p-1 bg-gray-100 rounded">{affiliateData.referral_code}</code>
                  </div>
                  <div>
                    <span className="font-semibold">Commission Rate:</span>
                    <span className="ml-2">{(affiliateData.commission_rate * 100).toFixed(0)}%</span>
                  </div>
                  <div>
                    <span className="font-semibold">Total Earnings:</span>
                    <span className="ml-2">${affiliateData.total_earnings.toFixed(2)}</span>
                  </div>
                </>
              )}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <label className="text-sm font-medium">About You</label>
              <Textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Tell us about yourself..."
                rows={4}
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Promotional Activities</label>
              <Textarea
                name="activity_description"
                value={formData.activity_description}
                onChange={handleInputChange}
                placeholder="How do you plan to promote our product?"
                rows={4}
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Website URL</label>
              <Input
                name="website_url"
                value={formData.website_url}
                onChange={handleInputChange}
                placeholder="https://..."
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Twitter Profile</label>
              <Input
                name="twitter_url"
                value={formData.twitter_url}
                onChange={handleInputChange}
                placeholder="https://twitter.com/..."
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">LinkedIn Profile</label>
              <Input
                name="linkedin_url"
                value={formData.linkedin_url}
                onChange={handleInputChange}
                placeholder="https://linkedin.com/in/..."
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Instagram Profile</label>
              <Input
                name="instagram_url"
                value={formData.instagram_url}
                onChange={handleInputChange}
                placeholder="https://instagram.com/..."
              />
            </div>

            <Button type="submit" disabled={isLoading} className="w-full">
              {isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : affiliateData ? (
                "Update Information"
              ) : (
                "Submit Application"
              )}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AffiliatePage;
