import { useNavigate } from "react-router-dom";
import { PricingCard } from "./PricingCard";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { motion } from "framer-motion";

export function PricingSection() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleUpgradeClick = async () => {
    try {
      const { data, error } = await supabase.functions.invoke('create-checkout');
      
      if (error) throw error;
      if (!data.url) throw new Error('No checkout URL returned');

      window.location.href = data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to start checkout process. Please try again.",
      });
    }
  };

  const handleContactUs = () => {
    window.location.href = "mailto:sales@fluentflow.ai";
  };

  return (
    <div className="py-24 bg-gradient-to-br from-white via-gray-50 to-blue-50">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Simple, Transparent Pricing
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Choose the plan that best fits your needs. All plans include our core features.
          </p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <PricingCard
              title="Free"
              price="$0"
              description="Perfect for trying out FluentFlow"
              features={[
                { text: "5 daily requests", included: true },
                { text: "2 active actions", included: true },
                { text: "Basic text enhancements", included: true },
                { text: "Custom actions", included: false },
                { text: "Priority support", included: false },
              ]}
              buttonText="Get Started"
              buttonVariant="outline"
              onClick={() => navigate("/login")}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <PricingCard
              title="Pro"
              price="$9.99"
              description="For power users who need more"
              features={[
                { text: "Unlimited requests", included: true },
                { text: "10 active actions", included: true },
                { text: "Advanced text enhancements", included: true },
                { text: "Custom actions", included: true },
                { text: "Priority support", included: true },
              ]}
              buttonText="Upgrade Now"
              popular={true}
              onClick={handleUpgradeClick}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <PricingCard
              title="Enterprise"
              price="Contact Us"
              description="Custom solutions for your organization"
              features={[
                { text: "Unlimited requests", included: true },
                { text: "Unlimited active actions", included: true },
                { text: "Custom integrations", included: true },
                { text: "Dedicated support", included: true },
                { text: "SLA guarantees", included: true },
              ]}
              buttonText="Contact Sales"
              buttonVariant="secondary"
              onClick={handleContactUs}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}