
import { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";

export const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const checkConsent = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data: consents } = await supabase
          .from('user_consents')
          .select('*')
          .eq('consent_type', 'cookies')
          .eq('is_active', true)
          .single();
        
        setShowBanner(!consents);
      } else {
        const hasConsented = localStorage.getItem('cookie_consent');
        setShowBanner(!hasConsented);
      }
    };

    checkConsent();
  }, []);

  const handleAccept = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    
    if (user) {
      await supabase.from('user_consents').insert({
        user_id: user.id,
        consent_type: 'cookies',
        ip_address: await fetch('https://api.ipify.org?format=json').then(r => r.json()).then(data => data.ip),
        user_agent: navigator.userAgent
      });
    } else {
      localStorage.setItem('cookie_consent', 'true');
    }
    
    setShowBanner(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookie_consent', 'false');
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 z-50 border-t">
      <div className="container mx-auto px-4 py-4">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          <p className="text-sm text-muted-foreground">
            We use cookies to enhance your browsing experience and analyze our traffic. 
            By clicking "Accept", you consent to our use of cookies. See our{' '}
            <a href="/privacy" className="underline hover:text-primary">Privacy Policy</a> for more information.
          </p>
          <div className="flex gap-4">
            <Button variant="outline" onClick={handleDecline}>
              Decline
            </Button>
            <Button onClick={handleAccept}>
              Accept
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
