import { useState, useEffect } from "react";
import { Loader, Plus, Crown, Lock } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ActionsTable } from "@/components/actions/ActionsTable";
import { CreateActionDialog } from "@/components/actions/CreateActionDialog";
import { useUserPrompts } from "@/hooks/useUserPrompts";
import { Tables } from "@/integrations/supabase/types/tables";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

type UserPrompt = Tables<'user_prompts'>;

const Actions = () => {
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState<Partial<UserPrompt>>({});
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [userLimits, setUserLimits] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();
  const { userPrompts, setUserPrompts, isLoading: isLoadingPrompts, handleDeleteUserPrompt } = useUserPrompts();

  useEffect(() => {
    const checkLimits = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase.rpc('check_user_limits', {
        user_id: user.id
      });

      if (error) {
        console.error('Error checking limits:', error);
        return;
      }

      setUserLimits(data);
      setIsLoading(false);
    };

    checkLimits();
  }, []);

  const handleUpgradeClick = async () => {
    try {
      const { data, error } = await supabase.functions.invoke('create-checkout');
      
      if (error) throw error;
      if (!data.url) throw new Error('No checkout URL returned');

      window.location.href = data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to start checkout process. Please try again.",
      });
    }
  };

  const handleEdit = (prompt: UserPrompt) => {
    if (!userLimits?.can_create_action) {
      toast({
        variant: "destructive",
        title: "Upgrade Required",
        description: "Please upgrade to Pro to edit actions.",
      });
      return;
    }
    setEditingId(prompt.id);
    setEditForm(prompt);
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditForm({});
  };

  const handleSave = async () => {
    setEditingId(null);
    setEditForm({});
  };

  const handleReorder = (reorderedPrompts: UserPrompt[]) => {
    setUserPrompts(reorderedPrompts);
  };

  if (isLoading || isLoadingPrompts) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  const totalActions = userPrompts.length;
  const activeActions = userPrompts.filter(prompt => prompt.is_active).length;
  const isPro = userLimits?.subscription_type === 'Pro';
  const maxActiveActions = isPro ? 10 : 3;
  const maxDailyRequests = isPro ? 'Unlimited' : '5';

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-purple-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900">
      <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8 space-y-8 animate-fade-in">
        <div className="glass-panel p-8 space-y-6">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold gradient-heading">
                  My Actions
                </h2>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Active Actions: {activeActions}/{maxActiveActions} {' '}
                  {!isPro && activeActions >= maxActiveActions && (
                    <span className="text-red-500">(Limit reached)</span>
                  )}
                </p>
              </div>
              <div className="flex flex-col sm:flex-row gap-3">
                {!isPro && (
                  <Button 
                    onClick={handleUpgradeClick} 
                    className="gradient-bg text-white hover:opacity-90 transition-opacity flex items-center gap-2"
                  >
                    <Crown className="h-4 w-4" />
                    Upgrade to Pro
                  </Button>
                )}
                <Button 
                  onClick={() => {
                    if (!userLimits?.can_create_action) {
                      toast({
                        variant: "destructive",
                        title: "Upgrade Required",
                        description: "Please upgrade to Pro to create custom actions.",
                      });
                      return;
                    }
                    setIsCreateDialogOpen(true);
                  }}
                  className="bg-white dark:bg-gray-800 text-gray-800 dark:text-white border border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors flex items-center gap-2"
                >
                  <Plus className="h-4 w-4 text-primary" />
                  Create Action
                </Button>
              </div>
            </div>
          </div>

          {userPrompts && userPrompts.length > 0 ? (
            <div className="bg-white/50 dark:bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-100 dark:border-gray-700 shadow-sm">
              <ActionsTable
                prompts={userPrompts}
                editingId={editingId}
                editForm={editForm}
                setEditForm={setEditForm}
                handleEdit={handleEdit}
                handleSave={handleSave}
                handleCancelEdit={handleCancelEdit}
                isUserPrompt
                onDelete={handleDeleteUserPrompt}
                onReorder={handleReorder}
              />
            </div>
          ) : (
            <div className="text-center py-12 bg-gradient-to-b from-white/50 to-gray-50/50 dark:from-gray-800/50 dark:to-gray-900/50 backdrop-blur-sm rounded-lg border border-gray-100 dark:border-gray-700">
              <div className="space-y-3">
                <Lock className="h-8 w-8 mx-auto text-gray-400" />
                <p className="text-gray-600 dark:text-gray-400">
                  No custom actions found. Create your first action!
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <CreateActionDialog 
        isOpen={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
      />
    </div>
  );
};

export default Actions;