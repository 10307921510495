import { Button } from "@/components/ui/button";
import { TableCell, TableRow } from "@/components/ui/table";
import { Pencil, Trash2, GripVertical } from "lucide-react";
import { EditActionDialog } from "./EditActionDialog";
import { DeleteActionDialog } from "./DeleteActionDialog";
import { ActionToggle } from "./ActionToggle";
import { useActionRow } from "@/hooks/useActionRow";
import { Tables } from "@/integrations/supabase/types/tables";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useQueryClient } from "@tanstack/react-query";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { UserLimits } from "@/types/supabase-rpc";

type UserPrompt = Tables<'user_prompts'>;

interface ActionRowProps {
  prompt: UserPrompt;
  onEdit: (prompt: UserPrompt) => void;
  onSave: () => Promise<void>;
  onCancel: () => void;
  editForm: Partial<UserPrompt>;
  setEditForm: (form: Partial<UserPrompt>) => void;
  isEditing: boolean;
  isUserPrompt?: boolean;
  onDelete?: (id: string) => Promise<void>;
}

export const ActionRow = ({
  prompt,
  onEdit,
  onSave,
  onCancel,
  editForm,
  setEditForm,
  isEditing,
  isUserPrompt = false,
  onDelete,
}: ActionRowProps) => {
  const [userLimits, setUserLimits] = useState<UserLimits | null>(null);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  
  const {
    isSaving,
    isEditDialogOpen,
    setIsEditDialogOpen,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    handleSave: handleSaveAction,
    handleDelete,
    handleOpenEditDialog,
    handleCloseEditDialog,
  } = useActionRow({
    prompt,
    onEdit,
    onSave,
    onCancel,
    isUserPrompt,
    onDelete,
  });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: prompt.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  useEffect(() => {
    const checkLimits = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase.rpc('check_user_limits', {
        user_id: user.id
      });

      if (error) {
        console.error('Error checking limits:', error);
        return;
      }

      setUserLimits(data as unknown as UserLimits);
    };

    checkLimits();
  }, []);

  return (
    <>
      <TableRow ref={setNodeRef} style={style} className="hover:bg-gray-50 transition-colors">
        <TableCell className="w-[50px]">
          <Button
            variant="ghost"
            size="icon"
            className="cursor-grab active:cursor-grabbing"
            {...attributes}
            {...listeners}
          >
            <GripVertical className="h-4 w-4 text-gray-400" />
          </Button>
        </TableCell>
        {isUserPrompt && (
          <TableCell className="w-[50px]">
            <ActionToggle
              id={prompt.id}
              isActive={prompt.is_active ?? true}
              onToggle={onSave}
            />
          </TableCell>
        )}
        <TableCell className="font-medium text-gray-700">
          {prompt.action}
        </TableCell>
        <TableCell className="text-gray-600">{prompt.prompt}</TableCell>
        <TableCell>
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="icon"
              onClick={handleOpenEditDialog}
              className="h-8 w-8 text-gray-600 hover:text-[#33C3F0] hover:bg-[#33C3F0]/10 transition-colors"
            >
              <Pencil className="h-4 w-4" />
            </Button>
            {isUserPrompt && onDelete && (
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setIsDeleteDialogOpen(true)}
                className="h-8 w-8 text-gray-600 hover:text-red-500 hover:bg-red-50 transition-colors"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            )}
          </div>
        </TableCell>
      </TableRow>

      <EditActionDialog
        isOpen={isEditDialogOpen}
        onOpenChange={setIsEditDialogOpen}
        editForm={editForm}
        setEditForm={setEditForm}
        onSave={handleSaveAction}
        isSaving={isSaving}
      />

      <DeleteActionDialog
        isOpen={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
        onDelete={handleDelete}
      />
    </>
  );
};