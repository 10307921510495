import { Card } from "@/components/ui/card";
const Terms = () => {
  return <div className="page-container">
      <Card className="content-section">
        <h1 className="section-title">Terms of Service</h1>
        <p className="text-muted-foreground mb-8">Last updated: February 15, 2025</p>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold gradient-heading mb-4">1. Acceptance of Terms</h2>
          <p className="text-muted-foreground leading-relaxed">
            By accessing and using QuickPick's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. 
            If you do not agree with any of these terms, you are prohibited from using or accessing our service.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold gradient-heading mb-4">2. Use License</h2>
          <p className="text-muted-foreground leading-relaxed">
            We grant you a personal, non-transferable, non-exclusive license to use our service for your personal or business needs, 
            subject to these Terms of Service and our Fair Use Policy.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold gradient-heading mb-4">3. Service Description</h2>
          <p className="text-muted-foreground leading-relaxed">
            QuickPick provides AI-powered text enhancement services through our web application and browser extension. 
            The service processes user-provided text to improve clarity, grammar, and style.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold gradient-heading mb-4">4. User Obligations</h2>
          <ul className="list-disc pl-6 space-y-2 text-muted-foreground">
            <li className="leading-relaxed">You must provide accurate information when creating an account</li>
            <li className="leading-relaxed">You are responsible for maintaining the security of your account</li>
            <li className="leading-relaxed">You agree not to misuse or attempt to circumvent our service limitations</li>
            <li className="leading-relaxed">You will not use the service for any illegal or unauthorized purpose</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold gradient-heading mb-4">5. Subscription and Payments</h2>
          <p className="text-muted-foreground leading-relaxed">
            Some features of our service require a paid subscription. Payment terms, refund policies, 
            and subscription details are provided during the purchase process.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold gradient-heading mb-4">6. Intellectual Property</h2>
          <p className="text-muted-foreground leading-relaxed">
            The service, including its original content, features, and functionality, is owned by QuickPick 
            and is protected by international copyright, trademark, and other intellectual property laws.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold gradient-heading mb-4">7. Limitation of Liability</h2>
          <p className="text-muted-foreground leading-relaxed">
            QuickPick shall not be liable for any indirect, incidental, special, consequential, or punitive damages 
            resulting from your use or inability to use the service.
          </p>
        </section>
      </Card>
    </div>;
};
export default Terms;