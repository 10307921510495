import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";
import { UserLimits } from "@/types/supabase-rpc";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

interface CreateActionDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export const CreateActionDialog = ({ isOpen, onOpenChange }: CreateActionDialogProps) => {
  const [newPrompt, setNewPrompt] = useState({ action: "", prompt: "" });
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleCreatePrompt = async () => {
    if (!newPrompt.action || !newPrompt.prompt) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: "Both action name and template are required",
      });
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");

      // Get the current highest position for this user
      const { data: existingPrompts } = await supabase
        .from('user_prompts')
        .select('position')
        .eq('user_id', user.id)
        .order('position', { ascending: false })
        .limit(1);

      const nextPosition = existingPrompts && existingPrompts.length > 0 
        ? (existingPrompts[0].position + 1) 
        : 1;

      // Check user limits before creating the action
      const { data: limits, error: limitsError } = await supabase.rpc('check_user_limits', {
        user_id: user.id
      });

      if (limitsError) throw limitsError;

      const userLimits = limits as unknown as UserLimits;
      const shouldBeActive = userLimits.active_actions < 10;

      const { error } = await supabase.from("user_prompts").insert({
        action: newPrompt.action,
        prompt: newPrompt.prompt,
        user_id: user.id,
        is_active: shouldBeActive,
        position: nextPosition
      });

      if (error) throw error;

      toast({
        title: "Success",
        description: shouldBeActive 
          ? "Action created successfully"
          : "Action created as inactive (maximum active actions reached)",
      });
      
      onOpenChange(false);
      setNewPrompt({ action: "", prompt: "" });
      queryClient.invalidateQueries({ queryKey: ["user-prompts"] });
    } catch (error) {
      console.error("Error creating action:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to create action",
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold bg-gradient-to-r from-[#33C3F0] to-[#6366f1] bg-clip-text text-transparent">
            Create New Action
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <div className="space-y-2">
            <label htmlFor="new-action" className="text-sm font-medium text-gray-700">
              Action Name
            </label>
            <Input
              id="new-action"
              value={newPrompt.action}
              onChange={(e) =>
                setNewPrompt({ ...newPrompt, action: e.target.value })
              }
              placeholder="e.g., Make Professional"
              className="border-gray-200 focus:border-[#33C3F0] focus:ring-[#33C3F0]"
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="new-prompt" className="text-sm font-medium text-gray-700">
              Action Template
            </label>
            <Textarea
              id="new-prompt"
              value={newPrompt.prompt}
              onChange={(e) =>
                setNewPrompt({ ...newPrompt, prompt: e.target.value })
              }
              placeholder="Enter your action template..."
              className="border-gray-200 focus:border-[#33C3F0] focus:ring-[#33C3F0] min-h-[100px]"
            />
          </div>
        </div>
        <DialogFooter className="gap-2 sm:gap-0">
          <Button
            variant="outline"
            onClick={() => {
              onOpenChange(false);
              setNewPrompt({ action: "", prompt: "" });
            }}
            className="border-gray-200 hover:bg-gray-50 transition-colors"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleCreatePrompt}
            className="bg-gradient-to-r from-[#33C3F0] to-[#6366f1] hover:opacity-90 transition-opacity"
          >
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};