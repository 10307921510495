import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useQuery } from "@tanstack/react-query";
import { CreateRoleForm } from "@/components/system-roles/CreateRoleForm";
import { RolesList } from "@/components/system-roles/RolesList";
import type { SystemRole } from "@/components/system-roles/RolesList";

const SystemRoles = () => {
  const [isCreating, setIsCreating] = useState(false);

  const { data: roles, isLoading } = useQuery({
    queryKey: ["system-roles"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("system_roles")
        .select("*")
        .order("name");

      if (error) {
        console.error("Error fetching roles:", error);
        throw error;
      }

      return data as SystemRole[];
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">System Roles</h1>
        <Button onClick={() => setIsCreating(true)} disabled={isCreating}>
          <Plus className="mr-2 h-4 w-4" /> Add New Role
        </Button>
      </div>

      {isCreating && (
        <CreateRoleForm onCancel={() => setIsCreating(false)} />
      )}

      <RolesList roles={roles || []} />
    </div>
  );
};

export default SystemRoles;