import { Switch } from "@/components/ui/switch";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useState } from "react";

interface ActionToggleProps {
  id: string;
  isActive: boolean;
  onToggle: () => Promise<void>;
}

interface UserLimits {
  subscription_type: 'free' | 'pro';
  daily_requests: number;
  active_actions: number;
  can_make_request: boolean;
  can_create_action: boolean;
  can_activate_action: boolean;
}

export const ActionToggle = ({ id, isActive: initialIsActive, onToggle }: ActionToggleProps) => {
  const { toast } = useToast();
  const [isActive, setIsActive] = useState(initialIsActive);

  const handleToggle = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      // Only check limits when activating an action
      if (!isActive) {
        const { data, error } = await supabase.rpc('check_user_limits', {
          user_id: user.id
        });

        if (error) throw error;
        
        // First assert to unknown, then to our specific type
        const limits = data as unknown as UserLimits;
        console.log('User limits:', limits); // Debug log
        
        if (!limits.can_activate_action) {
          const maxActions = limits.subscription_type === 'free' ? 3 : 10;
          toast({
            variant: "destructive",
            title: "Limit Reached",
            description: `You can only have ${maxActions} active actions on your ${limits.subscription_type} plan. Current active: ${limits.active_actions}`,
          });
          return;
        }
      }

      const { error } = await supabase
        .from('user_prompts')
        .update({ is_active: !isActive })
        .eq('id', id);

      if (error) throw error;

      setIsActive(!isActive);
      await onToggle();
      
      toast({
        title: "Success",
        description: `Action ${isActive ? 'deactivated' : 'activated'} successfully`,
      });
    } catch (error) {
      console.error("Error toggling action:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to toggle action status",
      });
    }
  };

  return (
    <Switch
      checked={isActive}
      onCheckedChange={handleToggle}
      aria-label="Toggle action"
    />
  );
};