import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";

interface NewSystemRole {
  name: string;
  description: string;
  is_active: boolean;
}

export const CreateRoleForm = ({ onCancel }: { onCancel: () => void }) => {
  const [newRole, setNewRole] = useState<NewSystemRole>({
    name: "",
    description: "",
    is_active: false,
  });
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleCreate = async () => {
    if (!newRole.name || !newRole.description) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: "Both name and description are required",
      });
      return;
    }

    const { error } = await supabase.from("system_roles").insert([newRole]);

    if (error) {
      console.error("Error creating role:", error);
      toast({
        variant: "destructive",
        title: "Error creating role",
        description: error.message,
      });
      return;
    }

    toast({
      title: "Success",
      description: "Role created successfully",
    });

    onCancel();
    queryClient.invalidateQueries({ queryKey: ["system-roles"] });
  };

  return (
    <div className="mb-8 p-4 border rounded-md">
      <h2 className="text-xl font-semibold mb-4">Create New Role</h2>
      <div className="space-y-4">
        <div>
          <Input
            placeholder="Role Name"
            value={newRole.name}
            onChange={(e) => setNewRole({ ...newRole, name: e.target.value })}
          />
        </div>
        <div>
          <Textarea
            placeholder="Role Description (This is the content that will be sent to OpenAI as the system role)"
            value={newRole.description}
            onChange={(e) =>
              setNewRole({ ...newRole, description: e.target.value })
            }
            className="min-h-[150px]"
          />
        </div>
        <div className="flex gap-2">
          <Button onClick={handleCreate}>Create Role</Button>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};