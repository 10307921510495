import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { ActionsTable } from "@/components/actions/ActionsTable";
import { AddActionDialog } from "@/components/admin/AddActionDialog";

interface UserPromptsDialogProps {
  userId: string | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const UserPromptsDialog = ({ userId, open, onOpenChange }: UserPromptsDialogProps) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState<any>({});
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: prompts } = useQuery({
    queryKey: ["user-prompts", userId],
    queryFn: async () => {
      if (!userId) return [];
      const { data, error } = await supabase
        .from("user_prompts")
        .select("*")
        .eq("user_id", userId)
        .order("position");

      if (error) throw error;
      return data;
    },
    enabled: !!userId,
  });

  const handleEdit = (prompt: any) => {
    setEditingId(prompt.id);
    setEditForm(prompt);
  };

  const handleSave = async () => {
    try {
      const { error } = await supabase
        .from("user_prompts")
        .update({
          action: editForm.action,
          prompt: editForm.prompt,
          updated_at: new Date().toISOString(),
        })
        .eq("id", editingId);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Prompt updated successfully",
      });

      setEditingId(null);
      setEditForm({});
      queryClient.invalidateQueries({ queryKey: ["user-prompts", userId] });
    } catch (error) {
      console.error("Error updating prompt:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update prompt",
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const { error } = await supabase
        .from("user_prompts")
        .delete()
        .eq("id", id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Prompt deleted successfully",
      });

      queryClient.invalidateQueries({ queryKey: ["user-prompts", userId] });
    } catch (error) {
      console.error("Error deleting prompt:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete prompt",
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>Manage User Actions</DialogTitle>
        </DialogHeader>
        <div className="flex justify-end mb-4">
          <Button onClick={() => setIsAddDialogOpen(true)}>
            <Plus className="h-4 w-4 mr-2" />
            Add Action
          </Button>
        </div>
        {prompts && (
          <ActionsTable
            prompts={prompts}
            editingId={editingId}
            editForm={editForm}
            setEditForm={setEditForm}
            handleEdit={handleEdit}
            handleSave={handleSave}
            handleCancelEdit={() => {
              setEditingId(null);
              setEditForm({});
            }}
            isUserPrompt
            onDelete={handleDelete}
          />
        )}
      </DialogContent>
      <AddActionDialog
        isOpen={isAddDialogOpen}
        onOpenChange={setIsAddDialogOpen}
        userId={userId}
      />
    </Dialog>
  );
};