import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Check } from "lucide-react";

interface PricingFeature {
  text: string;
  included: boolean;
}

interface PricingCardProps {
  title: string;
  price: string;
  description: string;
  features: PricingFeature[];
  buttonText: string;
  buttonVariant?: "default" | "outline" | "secondary" | "ghost" | "link" | "destructive";
  onClick: () => void;
  popular?: boolean;
}

export function PricingCard({
  title,
  price,
  description,
  features,
  buttonText,
  buttonVariant = "default",
  onClick,
  popular = false,
}: PricingCardProps) {
  return (
    <Card className={`relative h-full bg-gradient-to-br from-white to-gray-50/50 border-black/5 shadow-lg hover:shadow-xl transition-all duration-300 ${popular ? 'border-2 border-blue-500/20' : ''}`}>
      {popular && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
          <div className="px-3 py-1 text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-full shadow-md">
            Most Popular
          </div>
        </div>
      )}
      <CardHeader className="pb-6">
        <CardTitle className="text-xl bg-gradient-to-r from-gray-900 to-gray-600 bg-clip-text text-transparent">
          {title}
        </CardTitle>
        <div className="flex items-baseline gap-1 mt-2">
          <span className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            {price}
          </span>
          {price !== "Contact Us" && (
            <span className="text-gray-500">/month</span>
          )}
        </div>
        <p className="text-sm text-gray-500 mt-2">{description}</p>
      </CardHeader>
      <CardContent>
        <ul className="space-y-3">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-2">
              <Check 
                className={`h-4 w-4 ${
                  feature.included 
                    ? 'text-blue-500' 
                    : 'text-gray-300'
                }`} 
              />
              <span className={feature.included ? 'text-gray-700' : 'text-gray-400'}>
                {feature.text}
              </span>
            </li>
          ))}
        </ul>
      </CardContent>
      <CardFooter className="mt-6">
        <Button 
          variant={buttonVariant} 
          onClick={onClick} 
          className={`w-full ${
            buttonVariant === "default"
              ? "bg-gradient-to-r from-blue-600 to-purple-600 hover:opacity-90 text-white"
              : buttonVariant === "outline"
              ? "border-2 border-gray-200 hover:border-blue-500/20 hover:bg-gray-50"
              : "bg-gray-100 hover:bg-gray-200 text-gray-900"
          }`}
        >
          {buttonText}
        </Button>
      </CardFooter>
    </Card>
  );
}