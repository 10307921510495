
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ActionRow } from "./ActionRow";
import { Tables } from "@/integrations/supabase/types/tables";
import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Skeleton } from "@/components/ui/skeleton";

type UserPrompt = Tables<'user_prompts'>;

interface ActionsTableProps {
  prompts: UserPrompt[];
  editingId: string | null;
  editForm: Partial<UserPrompt>;
  setEditForm: (form: Partial<UserPrompt>) => void;
  handleEdit: (prompt: UserPrompt) => void;
  handleSave: () => Promise<void>;
  handleCancelEdit: () => void;
  isUserPrompt?: boolean;
  onDelete?: (id: string) => Promise<void>;
  onReorder?: (prompts: UserPrompt[]) => void;
  isLoading?: boolean;
}

export const ActionsTable = ({
  prompts,
  editingId,
  editForm,
  setEditForm,
  handleEdit,
  handleSave,
  handleCancelEdit,
  isUserPrompt = false,
  onDelete,
  onReorder,
  isLoading = false,
}: ActionsTableProps) => {
  const { toast } = useToast();
  const items = prompts.map(prompt => prompt.id);

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over || active.id === over.id) return;

    const oldIndex = prompts.findIndex(p => p.id === active.id);
    const newIndex = prompts.findIndex(p => p.id === over.id);

    const newPrompts = [...prompts];
    const [movedItem] = newPrompts.splice(oldIndex, 1);
    newPrompts.splice(newIndex, 0, movedItem);

    // Immediately update the UI through the parent component
    if (onReorder) {
      onReorder(newPrompts);
    }

    // Then update the database
    try {
      const updates = newPrompts.map((prompt, index) => ({
        id: prompt.id,
        position: index + 1,
        action: prompt.action,
        prompt: prompt.prompt,
        user_id: prompt.user_id,
        is_active: prompt.is_active
      }));

      const { error } = await supabase
        .from('user_prompts')
        .upsert(updates);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating positions:', error);
      // Revert the UI change if the database update fails
      if (onReorder) {
        onReorder(prompts);
      }
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update action positions",
      });
    }
  };

  if (isLoading) {
    return (
      <div className="rounded-md border min-h-[300px]">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[50px]"></TableHead>
              {isUserPrompt && <TableHead className="w-[50px]">Status</TableHead>}
              <TableHead>Action Name</TableHead>
              <TableHead>Template</TableHead>
              <TableHead className="w-[100px]">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {[1, 2, 3].map((index) => (
              <TableRow key={index}>
                <td className="p-4"><Skeleton className="h-6 w-6" /></td>
                {isUserPrompt && <td className="p-4"><Skeleton className="h-6 w-10" /></td>}
                <td className="p-4"><Skeleton className="h-6 w-32" /></td>
                <td className="p-4"><Skeleton className="h-6 w-64" /></td>
                <td className="p-4"><Skeleton className="h-6 w-20" /></td>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <div className="rounded-md border min-h-[300px]">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[50px]"></TableHead>
            {isUserPrompt && <TableHead className="w-[50px]">Status</TableHead>}
            <TableHead>Action Name</TableHead>
            <TableHead>Template</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            <TableBody>
              {prompts.map((prompt) => (
                <ActionRow
                  key={prompt.id}
                  prompt={prompt}
                  onEdit={handleEdit}
                  onSave={handleSave}
                  onCancel={handleCancelEdit}
                  editForm={editForm}
                  setEditForm={setEditForm}
                  isEditing={editingId === prompt.id}
                  isUserPrompt={isUserPrompt}
                  onDelete={onDelete}
                />
              ))}
            </TableBody>
          </SortableContext>
        </DndContext>
      </Table>
    </div>
  );
};
