import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { TableCell, TableRow } from "@/components/ui/table";
import { Pencil, Trash2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

interface PromptRowProps {
  prompt: {
    id: string;
    action: string;
    prompt: string;
  };
  onEdit: (prompt: { id: string; action: string; prompt: string }) => void;
  onSave: () => Promise<void>;
  onCancel: () => void;
  editForm: Partial<{ action: string; prompt: string }>;
  setEditForm: (form: Partial<{ action: string; prompt: string }>) => void;
  isEditing: boolean;
  isUserPrompt?: boolean;
  onDelete?: (id: string) => Promise<void>;
}

export const PromptRow = ({
  prompt,
  onEdit,
  onSave,
  onCancel,
  editForm,
  setEditForm,
  isEditing,
  isUserPrompt = false,
  onDelete,
}: PromptRowProps) => {
  const { toast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleSave = async () => {
    if (!editForm.action || !editForm.prompt) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: "Both action name and template are required",
      });
      return;
    }

    setIsSaving(true);
    try {
      if (isUserPrompt) {
        const { error } = await supabase
          .from('user_prompts')
          .update({
            action: editForm.action,
            prompt: editForm.prompt,
          })
          .eq('id', prompt.id);

        if (error) throw error;
      } else {
        const { error } = await supabase.functions.invoke('update-prompt', {
          body: { 
            promptId: prompt.id,
            action: editForm.action,
            prompt: editForm.prompt
          }
        });

        if (error) throw error;
      }

      await onSave();
      toast({
        title: "Success",
        description: "Action updated successfully",
      });
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error updating action:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update action",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (onDelete) {
      await onDelete(prompt.id);
      setIsDeleteDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    onEdit(prompt);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    onCancel();
  };

  return (
    <>
      <TableRow>
        <TableCell>{prompt.action}</TableCell>
        <TableCell>{prompt.prompt}</TableCell>
        <TableCell>
          <div className="flex gap-2">
            <Button
              variant="ghost"
              size="icon"
              onClick={handleOpenDialog}
              className="h-8 w-8"
            >
              <Pencil className="h-4 w-4" />
            </Button>
            {isUserPrompt && onDelete && (
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setIsDeleteDialogOpen(true)}
                className="h-8 w-8"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            )}
          </div>
        </TableCell>
      </TableRow>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Action</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <label htmlFor="action">Action Name</label>
              <Input
                id="action"
                value={editForm.action || ""}
                onChange={(e) =>
                  setEditForm({ ...editForm, action: e.target.value })
                }
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="prompt">Template</label>
              <Textarea
                id="prompt"
                value={editForm.prompt || ""}
                onChange={(e) =>
                  setEditForm({ ...editForm, prompt: e.target.value })
                }
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={handleCloseDialog}
              disabled={isSaving}
            >
              Cancel
            </Button>
            <Button onClick={handleSave} disabled={isSaving}>
              {isSaving ? "Saving..." : "Save Changes"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your action.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};