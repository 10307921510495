import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Tables } from "@/integrations/supabase/types/tables";

type UserPrompt = Tables<'user_prompts'>;

interface UseActionRowProps {
  prompt: UserPrompt;
  onEdit: (prompt: UserPrompt) => void;
  onSave: () => Promise<void>;
  onCancel: () => void;
  isUserPrompt?: boolean;
  onDelete?: (id: string) => Promise<void>;
}

export const useActionRow = ({
  prompt,
  onEdit,
  onSave,
  onCancel,
  isUserPrompt = false,
  onDelete,
}: UseActionRowProps) => {
  const { toast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onSave();
      setIsEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating action:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update action",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (onDelete) {
      await onDelete(prompt.id);
      setIsDeleteDialogOpen(false);
    }
  };

  const handleOpenEditDialog = () => {
    onEdit(prompt);
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    onCancel();
  };

  return {
    isSaving,
    isEditDialogOpen,
    setIsEditDialogOpen,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    handleSave,
    handleDelete,
    handleOpenEditDialog,
    handleCloseEditDialog,
  };
};