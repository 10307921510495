import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { Building2, Users2, Target, Phone } from "lucide-react";
const About = () => {
  return <div className="page-container">
      <motion.div initial={{
      opacity: 0,
      y: 20
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.5
    }}>
        <h1 className="section-title">About QuickPick</h1>
        <p className="section-subtitle">
          Empowering individuals and businesses to communicate more effectively through AI-powered text enhancement.
        </p>
      </motion.div>

      <div className="grid md:grid-cols-2 gap-8">
        <motion.div initial={{
        opacity: 0,
        x: -20
      }} animate={{
        opacity: 1,
        x: 0
      }} transition={{
        duration: 0.5,
        delay: 0.2
      }} className="content-section">
          <div className="flex items-start gap-4">
            <Building2 className="w-8 h-8 text-purple-500" />
            <div>
              <h2 className="text-xl font-semibold mb-2">Our Mission</h2>
              <p className="text-gray-600">
                At QuickPick, we're dedicated to transforming the way people write and communicate. 
                Our AI-powered platform helps you create clear, professional, and impactful content 
                with ease.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div initial={{
        opacity: 0,
        x: 20
      }} animate={{
        opacity: 1,
        x: 0
      }} transition={{
        duration: 0.5,
        delay: 0.3
      }} className="content-section">
          <div className="flex items-start gap-4">
            <Users2 className="w-8 h-8 text-purple-500" />
            <div>
              <h2 className="text-xl font-semibold mb-2">Our Team</h2>
              <p className="text-gray-600">
                We're a diverse team of engineers, linguists, and AI specialists working 
                together to build the future of written communication. Our expertise spans 
                natural language processing, user experience, and software development.
              </p>
            </div>
          </div>
        </motion.div>
      </div>

      <motion.div initial={{
      opacity: 0,
      y: 20
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.5,
      delay: 0.4
    }} className="content-section">
        <div className="flex items-start gap-4">
          <Target className="w-8 h-8 text-purple-500" />
          <div>
            <h2 className="text-xl font-semibold mb-2">Our Technology</h2>
            <p className="text-gray-600">
              FluentFlow leverages cutting-edge AI technology to analyze and enhance text in real-time. 
              We combine advanced natural language processing with intuitive interfaces to deliver 
              powerful yet simple-to-use writing tools that help you communicate more effectively.
            </p>
          </div>
        </div>
      </motion.div>

      <motion.div initial={{
      opacity: 0,
      y: 20
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.5,
      delay: 0.5
    }} className="glass-panel p-8">
        <div className="flex items-start gap-4">
          <Phone className="w-8 h-8 text-purple-500" />
          <div>
            <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
            <p className="text-gray-600 mb-4">
              We'd love to hear from you! Reach out to us with any questions, feedback, or partnership opportunities.
            </p>
            <ul className="space-y-2 text-gray-600">
              <li>Email: support@quick-pick.co</li>
              <li>Website: https://quick-pick.co</li>
              <li>Location: San Francisco, CA</li>
            </ul>
          </div>
        </div>
      </motion.div>
    </div>;
};
export default About;