import { motion } from "framer-motion";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Star, Quote } from "lucide-react";

const testimonials = [
  {
    name: "Sarah Johnson",
    role: "Content Manager",
    company: "TechCorp",
    content: "QuickPick has revolutionized our content creation process. What used to take hours now takes minutes. The AI enhancement is incredibly intuitive!",
    rating: 5
  },
  {
    name: "Michael Chen",
    role: "Marketing Director",
    company: "GrowthLabs",
    content: "The productivity boost is remarkable. Our team's writing efficiency has increased tenfold, and the quality of our content has never been better.",
    rating: 5
  },
  {
    name: "Emma Davis",
    role: "Freelance Writer",
    company: "Self-employed",
    content: "As a freelancer, time is money. QuickPick helps me deliver more high-quality content to my clients in less time. It's a game-changer!",
    rating: 5
  }
];

export const TestimonialsSection = () => {
  return (
    <div className="container mx-auto px-4 py-16 mb-16">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center mb-12"
      >
        <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          Loved by Content Creators Worldwide
        </h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Join thousands of satisfied users who have transformed their writing workflow
        </p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ 
              scale: 1.02,
              transition: { duration: 0.2 }
            }}
          >
            <Card className="h-full bg-gradient-to-br from-white to-gray-50/50 border-black/5 shadow-lg hover:shadow-xl transition-all duration-300">
              <CardHeader className="pb-2">
                <div className="flex justify-between items-start">
                  <div className="flex items-center gap-2">
                    <Quote className="h-6 w-6 text-blue-500 rotate-180" />
                  </div>
                  <div className="flex">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} className="h-4 w-4 text-yellow-400 fill-yellow-400" />
                    ))}
                  </div>
                </div>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600 leading-relaxed mb-4 italic">
                  "{testimonial.content}"
                </p>
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-900">{testimonial.name}</span>
                  <span className="text-sm text-gray-500">{testimonial.role} at {testimonial.company}</span>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
};