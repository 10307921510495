
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Tables } from "@/integrations/supabase/types/tables";
import { useState, useEffect } from "react";
import { useActionsCache } from "./useActionsCache";

type UserPrompt = Tables<'user_prompts'>;

export const useUserPrompts = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [userPrompts, setUserPrompts] = useState<UserPrompt[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  
  // Get the current user's ID
  const [userId, setUserId] = useState<string>();
  const { cache, updateCache, getCachedActions } = useActionsCache(userId);

  useEffect(() => {
    const getCurrentUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUserId(user.id);
      }
    };
    getCurrentUser();
  }, []);

  const { isLoading } = useQuery({
    queryKey: ["user-prompts", userId],
    queryFn: async () => {
      if (!userId) {
        throw new Error("No authenticated user found");
      }

      // First check if we have cached data
      const cachedData = await getCachedActions();
      if (cachedData) {
        setUserPrompts(cachedData.actions);
        setIsInitialLoad(false);
      }

      // Then fetch fresh data
      const { data, error } = await supabase
        .from("user_prompts")
        .select("*")
        .eq('user_id', userId)
        .order("position");

      if (error) {
        console.error("Error fetching user actions:", error);
        toast({
          variant: "destructive",
          title: "Error fetching actions",
          description: error.message,
        });
        throw error;
      }

      // Update state and cache
      setUserPrompts(data);
      await updateCache(data, false);
      setIsInitialLoad(false);
      return data as UserPrompt[];
    },
    enabled: !!userId,
  });

  const handleDeleteUserPrompt = async (id: string) => {
    try {
      const { error } = await supabase
        .from("user_prompts")
        .delete()
        .eq("id", id);

      if (error) throw error;

      const updatedPrompts = userPrompts.filter(prompt => prompt.id !== id);
      setUserPrompts(updatedPrompts);
      await updateCache(updatedPrompts);

      toast({
        title: "Success",
        description: "Action deleted successfully",
      });
      queryClient.invalidateQueries({ queryKey: ["user-prompts"] });
    } catch (error) {
      console.error("Error deleting action:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete action",
      });
    }
  };

  return {
    userPrompts,
    setUserPrompts,
    isLoading: isLoading && isInitialLoad,
    handleDeleteUserPrompt,
  };
};
