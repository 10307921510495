import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "@/components/ui/dialog";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";

interface EditActionDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  editForm: Partial<{ id: string; action: string; prompt: string }>;
  setEditForm: (form: Partial<{ id: string; action: string; prompt: string }>) => void;
  onSave: () => Promise<void>;
  isSaving: boolean;
}

export const EditActionDialog = ({
  isOpen,
  onOpenChange,
  editForm,
  setEditForm,
  onSave,
  isSaving,
}: EditActionDialogProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleSave = async () => {
    if (!editForm.action || !editForm.prompt) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please fill in all fields",
      });
      return;
    }

    try {
      const { error } = await supabase
        .from('user_prompts')
        .update({
          action: editForm.action,
          prompt: editForm.prompt,
          updated_at: new Date().toISOString(),
        })
        .eq('id', editForm.id);

      if (error) throw error;

      await queryClient.invalidateQueries({ queryKey: ['user-prompts'] });
      await onSave();
      
      toast({
        title: "Success",
        description: "Action updated successfully",
      });
      
      onOpenChange(false);
    } catch (error) {
      console.error('Error updating action:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update action",
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Action</DialogTitle>
          <DialogDescription>
            Make changes to your action below.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <div className="space-y-2">
            <label htmlFor="action">Action Name</label>
            <Input
              id="action"
              value={editForm.action || ""}
              onChange={(e) =>
                setEditForm({ ...editForm, action: e.target.value })
              }
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="prompt">Template</label>
            <Textarea
              id="prompt"
              value={editForm.prompt || ""}
              onChange={(e) =>
                setEditForm({ ...editForm, prompt: e.target.value })
              }
            />
          </div>
        </div>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={isSaving}>
            {isSaving ? "Saving..." : "Save Changes"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};