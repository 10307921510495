import { Card } from "@/components/ui/card";
const Privacy = () => {
  return <div className="container mx-auto px-4 py-12 max-w-4xl">
      <Card className="glass-panel">
        <div className="p-8 space-y-8">
          <header className="text-center mb-12">
            <h1 className="section-title">Privacy Policy</h1>
            <p className="text-muted-foreground">Last updated: January 15, 2025</p>
          </header>
          
          <section className="content-section">
            <h2 className="text-2xl font-semibold mb-4 gradient-heading">1. Introduction</h2>
            <p className="text-gray-700 mb-4">
              Welcome to QuickPick ("we," "our," or "us"). We respect your privacy and are committed to protecting your personal data. 
              This privacy policy explains how we collect, use, and safeguard your information when you use our service.
            </p>
          </section>

          <section className="content-section">
            <h2 className="text-2xl font-semibold mb-4 gradient-heading">2. Your Rights Under GDPR</h2>
            <p className="text-gray-700 mb-4">Under the GDPR, you have the following rights:</p>
            <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
              <li>Right to access your personal data</li>
              <li>Right to rectification of inaccurate personal data</li>
              <li>Right to erasure ('right to be forgotten')</li>
              <li>Right to restrict processing</li>
              <li>Right to data portability</li>
              <li>Right to object to processing</li>
              <li>Rights related to automated decision making and profiling</li>
            </ul>
            <p className="text-gray-700 mb-4">
              To exercise any of these rights, please visit your account settings or contact us using the information provided below.
            </p>
          </section>

          <section className="content-section">
            <h2 className="text-2xl font-semibold mb-4 gradient-heading">3. Information We Collect</h2>
            <p className="text-gray-700 mb-4">We collect and process the following types of information:</p>
            <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
              <li>Email address (for authentication purposes)</li>
              <li>Text content that you choose to enhance through our service</li>
              <li>Usage data (how you interact with our service)</li>
              <li>Technical data (browser type, device information)</li>
            </ul>
          </section>

          <section className="content-section">
            <h2 className="text-2xl font-semibold mb-4 gradient-heading">4. Legal Basis for Processing</h2>
            <p className="text-gray-700 mb-4">We process your personal data under the following legal bases:</p>
            <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
              <li>Consent: For non-essential cookies and marketing communications</li>
              <li>Contract: To provide our services to you</li>
              <li>Legal Obligation: To comply with legal requirements</li>
              <li>Legitimate Interests: To improve our services and protect against fraud</li>
            </ul>
          </section>

          <section className="content-section">
            <h2 className="text-2xl font-semibold mb-4 gradient-heading">5. How We Use Your Information</h2>
            <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
              <li>To provide and maintain our service</li>
              <li>To authenticate you with our service</li>
              <li>To process your text enhancement requests</li>
              <li>To improve our service and user experience</li>
              <li>To communicate with you about service updates</li>
            </ul>
          </section>

          <section className="content-section">
            <h2 className="text-2xl font-semibold mb-4 gradient-heading">6. Data Storage and Security</h2>
            <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
              <li>We use Supabase's infrastructure for secure data storage</li>
              <li>All data transmissions are encrypted using SSL/TLS protocols</li>
              <li>We do not store your enhanced text content after processing</li>
              <li>We regularly review and update our security measures</li>
            </ul>
          </section>

          <section className="content-section">
            <h2 className="text-2xl font-semibold mb-4 gradient-heading">7. Your Rights</h2>
            <p className="text-gray-700 mb-4">Under data protection laws, you have rights including:</p>
            <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
              <li>The right to access your personal data</li>
              <li>The right to correct inaccurate personal data</li>
              <li>The right to request deletion of your personal data</li>
              <li>The right to restrict processing of your personal data</li>
              <li>The right to data portability</li>
            </ul>
          </section>

          <section className="content-section">
            <h2 className="text-2xl font-semibold mb-4 gradient-heading">8. Contact Us</h2>
            <p className="text-gray-700 mb-4">Email: support@quick-pick.co</p>
            <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
              <li>Email: support@quick-pick.co</li>
              <li>Website: https://quick-pick.co</li>
            </ul>
          </section>

          <section className="content-section">
            <h2 className="text-2xl font-semibold mb-4 gradient-heading">9. Data Protection Officer</h2>
            <p className="text-gray-700 mb-4">
              For any questions about our privacy practices or to exercise your rights, you can contact our Data Protection Officer at:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
              <li>Email: dpo@quick-pick.co</li>
              <li>Address: [Your Company Address]</li>
            </ul>
          </section>

          <section className="content-section">
            <h2 className="text-2xl font-semibold mb-4 gradient-heading">10. Supervisory Authority</h2>
            <p className="text-gray-700 mb-4">
              If you are in the EU/EEA, you have the right to lodge a complaint with your local data protection authority if you are unhappy with how we process your personal data.
            </p>
          </section>
        </div>
      </Card>
    </div>;
};
export default Privacy;
