import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Pencil, Save, X } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";

export interface SystemRole {
  id: string;
  name: string;
  description: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

export const RolesList = ({ roles }: { roles: SystemRole[] }) => {
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState<Partial<SystemRole>>({});
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleEdit = (role: SystemRole) => {
    setEditingId(role.id);
    setEditForm(role);
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditForm({});
  };

  const handleSave = async () => {
    if (!editForm.name || !editForm.description) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: "Both name and description are required",
      });
      return;
    }

    const { error } = await supabase
      .from("system_roles")
      .update({
        name: editForm.name,
        description: editForm.description,
        is_active: editForm.is_active,
      })
      .eq("id", editingId);

    if (error) {
      console.error("Error updating role:", error);
      toast({
        variant: "destructive",
        title: "Error updating role",
        description: error.message,
      });
      return;
    }

    toast({
      title: "Success",
      description: "Role updated successfully",
    });

    setEditingId(null);
    setEditForm({});
    queryClient.invalidateQueries({ queryKey: ["system-roles"] });
  };

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>System Role Content</TableHead>
            <TableHead>Status</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {roles?.map((role) => (
            <TableRow key={role.id}>
              <TableCell>
                {editingId === role.id ? (
                  <Input
                    value={editForm.name}
                    onChange={(e) =>
                      setEditForm({ ...editForm, name: e.target.value })
                    }
                  />
                ) : (
                  role.name
                )}
              </TableCell>
              <TableCell>
                {editingId === role.id ? (
                  <Textarea
                    value={editForm.description}
                    onChange={(e) =>
                      setEditForm({ ...editForm, description: e.target.value })
                    }
                    className="min-h-[150px]"
                    placeholder="This content will be sent to OpenAI as the system role"
                  />
                ) : (
                  <div className="max-h-[100px] overflow-y-auto">
                    {role.description}
                  </div>
                )}
              </TableCell>
              <TableCell>
                {editingId === role.id ? (
                  <Input
                    type="checkbox"
                    checked={editForm.is_active}
                    onChange={(e) =>
                      setEditForm({ ...editForm, is_active: e.target.checked })
                    }
                  />
                ) : (
                  role.is_active ? "Active" : "Inactive"
                )}
              </TableCell>
              <TableCell>
                {editingId === role.id ? (
                  <div className="flex gap-2">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={handleSave}
                      className="h-8 w-8"
                    >
                      <Save className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={handleCancelEdit}
                      className="h-8 w-8"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleEdit(role)}
                    className="h-8 w-8"
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};