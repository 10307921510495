import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/hooks/use-toast";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { UserLimits } from "@/types/supabase-rpc";
import { useNavigate } from "react-router-dom";
import {
  LayoutDashboard,
  Settings,
  PlusCircle,
  Zap,
  Crown,
  Users,
} from "lucide-react";

const Dashboard = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>();
  const { toast } = useToast();

  const handleUpgradeClick = async () => {
    try {
      const { data, error } = await supabase.functions.invoke('create-checkout');
      
      if (error) throw error;
      if (!data.url) throw new Error('No checkout URL returned');

      window.location.href = data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to start checkout process. Please try again.",
      });
    }
  };

  const { data: session } = useQuery({
    queryKey: ["session"],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUserId(user.id);
        return user;
      }
      return null;
    },
  });

  const { data: userLimits, isLoading: limitsLoading } = useQuery<UserLimits>({
    queryKey: ["user-limits", userId],
    queryFn: async () => {
      if (!userId) throw new Error("No user ID");
      const { data, error } = await supabase.rpc('check_user_limits', {
        user_id: userId
      });
      if (error) throw error;
      return (data as unknown) as UserLimits;
    },
    enabled: !!userId,
  });

  const { data: usageStats, isLoading: statsLoading } = useQuery({
    queryKey: ["usage-stats", userId],
    queryFn: async () => {
      if (!userId) throw new Error("No user ID");
      const { data, error } = await supabase
        .from("daily_requests")
        .select("date, request_count")
        .eq("user_id", userId)
        .order("date", { ascending: true })
        .limit(7);
      
      if (error) throw error;
      return data;
    },
    enabled: !!userId,
  });

  const { data: actionStats, isLoading: actionsLoading } = useQuery({
    queryKey: ["action-stats", userId],
    queryFn: async () => {
      if (!userId) throw new Error("No user ID");
      const { data: userPrompts, error } = await supabase
        .from("user_prompts")
        .select("action")
        .eq("user_id", userId)
        .eq("is_active", true);

      if (error) throw error;
      return userPrompts;
    },
    enabled: !!userId,
  });

  const { data: mostUsedAction, isLoading: actionLoading } = useQuery({
    queryKey: ["most-used-action", userId],
    queryFn: async () => {
      if (!userId) throw new Error("No user ID");
      const { data, error } = await supabase
        .from("user_prompts")
        .select("action, is_active")
        .eq("user_id", userId)
        .eq("is_active", true)
        .order("action", { ascending: true })
        .limit(1)
        .single();
      
      if (error) throw error;
      return data;
    },
    enabled: !!userId,
  });

  const isLoading = limitsLoading || statsLoading || actionsLoading || actionLoading;

  return (
    <div className="page-container">
      <h1 className="section-title">Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <Card className="hover:shadow-lg transition-shadow duration-300 bg-gradient-to-br from-purple-50 to-pink-50 dark:from-purple-900/20 dark:to-pink-900/20">
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-lg">
              <Crown className="text-primary h-5 w-5" />
              Plan Status
            </CardTitle>
          </CardHeader>
          <CardContent>
            {isLoading ? (
              <Skeleton className="h-20" />
            ) : (
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <span className="text-sm text-muted-foreground">Current Plan</span>
                  <Badge variant={userLimits?.subscription_type.toLowerCase() === "pro" ? "default" : "secondary"} className="capitalize">
                    {userLimits?.subscription_type || "free"}
                  </Badge>
                </div>
                <Button 
                  variant={userLimits?.subscription_type.toLowerCase() === "pro" ? "secondary" : "default"}
                  className="w-full"
                  onClick={userLimits?.subscription_type.toLowerCase() === "pro" ? 
                    () => window.location.href = "https://billing.stripe.com/p/login/test_28o5kN8Tefgk7pC288" : 
                    handleUpgradeClick}
                >
                  {userLimits?.subscription_type.toLowerCase() === "pro" ? "Manage Subscription" : "Upgrade to Pro"}
                </Button>
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="hover:shadow-lg transition-shadow duration-300 bg-gradient-to-br from-blue-50 to-cyan-50 dark:from-blue-900/20 dark:to-cyan-900/20">
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-lg">
              <Zap className="text-primary h-5 w-5" />
              Daily Usage
            </CardTitle>
          </CardHeader>
          <CardContent>
            {isLoading ? (
              <Skeleton className="h-20" />
            ) : (
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <span className="text-sm text-muted-foreground">Requests Today</span>
                </div>
                <div className="text-2xl font-bold">
                  {userLimits?.daily_requests || 0} / {userLimits?.subscription_type === "pro" ? "∞" : "10"}
                </div>
              </div>
            )}
          </CardContent>
        </Card>

        <Card className="hover:shadow-lg transition-shadow duration-300 bg-gradient-to-br from-green-50 to-emerald-50 dark:from-green-900/20 dark:to-emerald-900/20">
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-lg">
              <Users className="text-primary h-5 w-5" />
              Active Actions
            </CardTitle>
          </CardHeader>
          <CardContent>
            {isLoading ? (
              <Skeleton className="h-20" />
            ) : (
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <span className="text-sm text-muted-foreground">Current Actions</span>
                </div>
                <div className="text-2xl font-bold">
                  {userLimits?.active_actions || 0} / {userLimits?.subscription_type.toLowerCase() === "pro" ? "10" : "3"}
                </div>
                {mostUsedAction && (
                  <div className="pt-2 border-t">
                    <span className="text-sm text-muted-foreground">Most Used Action:</span>
                    <div className="font-medium truncate">{mostUsedAction.action}</div>
                  </div>
                )}
              </div>
            )}
          </CardContent>
        </Card>
      </div>

      <Card className="mb-6 hover:shadow-lg transition-shadow duration-300 bg-gradient-to-br from-white to-gray-50 dark:from-gray-900/50 dark:to-gray-900/30">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Zap className="text-primary" />
            Quick Actions
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <Button variant="secondary" onClick={() => navigate("/actions")} className="w-full">
              <LayoutDashboard className="mr-2" /> Actions
            </Button>
            <Button variant="secondary" onClick={() => navigate("/profile")} className="w-full">
              <Settings className="mr-2" /> Profile
            </Button>
            <Button variant="secondary" onClick={() => navigate("/actions")} className="w-full">
              <PlusCircle className="mr-2" /> New Action
            </Button>
            {userLimits?.subscription_type !== "pro" && (
              <Button variant="default" onClick={() => navigate("/pricing")} className="w-full">
                <Crown className="mr-2" /> Upgrade
              </Button>
            )}
          </div>
        </CardContent>
      </Card>

      <Card className="mb-6 hover:shadow-lg transition-shadow duration-300">
        <CardHeader>
          <CardTitle>Usage Trends</CardTitle>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <Skeleton className="h-[300px]" />
          ) : (
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={usageStats}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                  <XAxis dataKey="date" stroke="#888" fontSize={12} tickLine={false} axisLine={false} />
                  <YAxis stroke="#888" fontSize={12} tickLine={false} axisLine={false} />
                  <Tooltip />
                  <Line 
                    type="monotone" 
                    dataKey="request_count" 
                    stroke="hsl(var(--primary))"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Dashboard;
