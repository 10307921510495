
import { useEffect, useState } from "react";
import { Tables } from "@/integrations/supabase/types/tables";

type UserPrompt = Tables<'user_prompts'>;

interface ActionsCache {
  actions: UserPrompt[];
  version: number;
  userId: string | null;
  lastFetched: number;
  expiresAt: number;
}

const CACHE_EXPIRY_TIME = 30 * 60 * 1000; // 30 minutes
const isExtension = typeof chrome !== 'undefined' && chrome.storage;

export const useActionsCache = (userId: string | undefined) => {
  const [cache, setCache] = useState<ActionsCache | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getCachedActions = async () => {
    if (!isExtension) return null;
    
    try {
      const result = await chrome.storage.local.get(['actionsCache']);
      const cachedData = result.actionsCache as ActionsCache;
      
      if (!cachedData) return null;

      // Check if cache has expired
      if (Date.now() > cachedData.expiresAt) {
        await chrome.storage.local.remove(['actionsCache']);
        return null;
      }

      // Return cache if it matches the current user or if no user is required
      if (!userId || cachedData.userId === userId) {
        return cachedData;
      }

      return null;
    } catch (error) {
      console.error('Error getting cached actions:', error);
      setError('Failed to retrieve cached actions');
      return null;
    }
  };

  const updateCache = async (actions: UserPrompt[], incrementVersion = true) => {
    if (!isExtension) return;

    try {
      const currentCache = await getCachedActions();
      const newCache: ActionsCache = {
        actions,
        version: currentCache ? (incrementVersion ? currentCache.version + 1 : currentCache.version) : 1,
        userId: userId || null,
        lastFetched: Date.now(),
        expiresAt: Date.now() + CACHE_EXPIRY_TIME
      };
      
      await chrome.storage.local.set({ actionsCache: newCache });
      setCache(newCache);
      setError(null);
    } catch (error) {
      console.error('Error updating actions cache:', error);
      setError('Failed to update actions cache');
    }
  };

  const clearCache = async () => {
    if (!isExtension) return;
    try {
      await chrome.storage.local.remove(['actionsCache']);
      setCache(null);
      setError(null);
    } catch (error) {
      console.error('Error clearing cache:', error);
      setError('Failed to clear cache');
    }
  };

  useEffect(() => {
    const initializeCache = async () => {
      const cachedData = await getCachedActions();
      if (cachedData) {
        setCache(cachedData);
      }
    };

    initializeCache();
  }, [userId]);

  return {
    cache,
    updateCache,
    getCachedActions,
    clearCache,
    error
  };
};
