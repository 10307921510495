import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        navigate("/");
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN" && session) {
        navigate("/");
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-50 via-white to-purple-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 p-4">
      <div className="w-full max-w-md glass-panel bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm border border-purple-100 dark:border-gray-700 shadow-xl rounded-lg p-8">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold gradient-heading bg-gradient-to-r from-purple-600 via-purple-500 to-purple-400 bg-clip-text text-transparent">
            Welcome Back
          </h1>
          <p className="text-gray-600 dark:text-gray-300 mt-2">
            Sign in to continue to Quick Pick
          </p>
        </div>
        
        <Auth
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: "#8B5CF6",
                  brandAccent: "#7C3AED",
                  brandButtonText: "white",
                  defaultButtonBackground: "white",
                  defaultButtonBackgroundHover: "#F9FAFB",
                  inputBackground: "white",
                  inputBorder: "#E5E7EB",
                  inputBorderHover: "#9B87F5",
                  inputBorderFocus: "#7E69AB",
                },
                borderWidths: {
                  buttonBorderWidth: "1px",
                  inputBorderWidth: "1px",
                },
              },
            },
            className: {
              container: "space-y-4",
              button: "w-full px-4 py-2 rounded-lg font-medium transition-colors",
              input: "w-full px-4 py-2 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-shadow",
              label: "block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1",
            },
          }}
          theme="default"
          providers={[]}
          view="sign_in"
        />
      </div>
    </div>
  );
};

export default Login;
