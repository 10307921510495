import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { LogIn, LogOut, User, Menu, Crown, Lock } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const Header = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [userLimits, setUserLimits] = useState<any>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const checkLimits = async () => {
      if (!user) return;

      const { data, error } = await supabase.rpc('check_user_limits', {
        user_id: user.id
      });

      if (error) {
        console.error('Error checking limits:', error);
        return;
      }

      setUserLimits(data);
    };

    if (user) {
      checkLimits();
    }
  }, [user]);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate("/login");
  };

  const isPro = userLimits?.subscription_type === 'Pro';
  const maxDailyRequests = isPro ? 'Unlimited' : '5';

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-sm border-b border-gray-200">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center space-x-3 cursor-pointer" onClick={() => navigate("/")}>
            <img 
              src="/lovable-uploads/b500875d-a182-409a-962c-dc47a4b861af.png" 
              alt="Quick Pick Logo" 
              className="h-8 w-8"
            />
            <span className="text-[#76309b] font-semibold text-xl">QuickPick</span>
          </div>
          <div className="flex items-center space-x-4">
            {user ? (
              <div className="flex items-center gap-4">
                <div className="flex flex-col items-end gap-1">
                  <div className="flex items-center gap-2">
                    <User className="h-4 w-4 text-gray-600" />
                    <span className="text-sm text-gray-600">{user.email}</span>
                  </div>
                  {userLimits && (
                    <div className="flex items-center gap-2">
                      {isPro ? (
                        <div className="flex items-center gap-2 text-sm">
                          <Crown className="h-4 w-4 text-yellow-500" />
                          <span className="text-sm font-medium bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text text-transparent">
                            Pro Plan
                          </span>
                        </div>
                      ) : (
                        <div className="flex items-center gap-2 text-sm text-gray-600">
                          <Lock className="h-4 w-4" />
                          <span>
                            Free Plan ({userLimits.daily_requests}/{maxDailyRequests})
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      className="h-8 w-8 border-gray-200 hover:border-[#33C3F0] transition-colors"
                    >
                      <Menu className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-48">
                    <DropdownMenuItem onClick={() => navigate("/profile")}>
                      Profile
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => navigate("/actions")}>
                      My Actions
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleLogout}>
                      Logout
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            ) : (
              <Button
                variant="outline"
                size="sm"
                onClick={handleLogin}
                className="flex items-center gap-2 border-[#33C3F0] text-[#33C3F0] hover:bg-[#33C3F0] hover:text-white transition-all"
              >
                <LogIn className="h-4 w-4" />
                Login
              </Button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;